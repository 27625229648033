@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

.AccordionContent {
	overflow: hidden;
  }
  .AccordionContent[data-state='open'] {
	animation: slideDown 300ms ease-out;
  }
  .AccordionContent[data-state='closed'] {
	animation: slideUp 300ms ease-out;
  }
  
  @keyframes slideDown {
	from {
	  height: 0;
	}
	to {
	  height: var(--radix-accordion-content-height);
	}
  }
  
  @keyframes slideUp {
	from {
	  height: var(--radix-accordion-content-height);
	}
	to {
	  height: 0;
	}
  }

  /* Scrollbar Track */
::-webkit-scrollbar {
	width: 8px; /* Width of the scrollbar */
	
  }
  
  /* Scrollbar Handle */
  ::-webkit-scrollbar-thumb {
	background-color: #d2dfc4; /* Color of the scrollbar handle */
	border-radius: 40px; /* Radius of the scrollbar handle */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background-color: #333; /* Color of the scrollbar handle on hover */
  }

  :root {
	--lightPrimary: #e9ebd5;
	--blueSecondary: #589E00;
	--brandLinear: #868CFF;
  }

  

/* Updated animation with slide-up effect */

.route-entering {
	opacity: 0;
	transform: translateY(20px); /* Start 20px below final position */
	animation: slideUpFadeIn 0.3s ease-out forwards;
  }
  
  .route-entered {
	opacity: 1;
	transform: translateY(0);
  }
  
  @keyframes slideUpFadeIn {
	from {
	  opacity: 0;
	  transform: translateY(20px);
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	}
  }

  html[lang="ta"] body {
	font-family: 'Noto Sans Tamil', sans-serif; /* Tamil */
  }
  
  html[lang="ur"] body {
	font-family: 'Noto Nastaliq Urdu', sans-serif; /* Urdu */
  }
  
  html[lang="ar"] body {
	font-family: 'Noto Sans Arabic', sans-serif; /* Arabic */
  }